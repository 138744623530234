<template>
    <div class="reportBillCost defaultReport">
        <div class="filter-box">
            <div class="filter-bnts ">
                <div class="bnt verticalCenter" :class="{selected:queryType==false}" @click="nowadays()" >
                    <div class="title">
                        <span>今天<br/>({{userInfo.Rpt_Date}})</span>
                    </div>
                </div>
                <div class="bnt verticalCenter" :class="{selected:queryType==true}" @click="queryType=true" v-if="ReportDataFromDays>=0">
                    <div class="title">
                        <span>按日期范围</span>
                    </div>
                </div>
                <div class="inline-block" v-if="ReportDataFromDays>=0">
                    <el-date-picker class="from-date"
                        v-model="businessHours"
                        type="daterange"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :default-time="defaultTime"
                        @change="change"
                        :disabled='queryType==false'
                        :disabled-date="disabledDate"
                    > </el-date-picker>
                </div>
                <div class="inline-block" >
                    <div class="flex-box">
                        <div class="lable-txt">餐厅:</div>
                        <div class="from-input" style="width: 180px;">
                            <el-select v-model="roomids" multiple collapse-tags @removeTag="()=>roomids=[]">
                                <el-option :value="item.Room_AutoID" :label="item.Room_Code+'-'+ item.Room_Name" v-for="item in PosRooms" :key="item"></el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
                <div class="inline-block">
                    <div class="flex-box eat">
                        <div class="lable-txt">时段:</div>
                        <div class="from-input">
                            <el-select v-model="salesValue" placeholder="全天" multiple collapse-tags @removeTag="()=>salesValue=[]">
                                <el-option
                                    v-for="item in eatPhases"
                                    :key="item.EAT_PHASEID"
                                    :label="item.EAT_PHASENAME"
                                    :value="item.EAT_PHASEID">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
                <div class="inline-block" >
                    <div class="flex-box">
                        <div class="lable-txt">消费类型:</div>
                        <div class="from-input" style="width: 180px;">
                            <select-tree v-model="typeAutoIDs" placeholder="请选择" :data="treeConsumeTypes" title="消费类型选择">
                            </select-tree>
                        </div>
                    </div>
                </div>
                <div class="inline-block" >
                    <div class="flex-box">
                        <div class="lable-txt">销售渠道:</div>
                        <div class="from-input" style="width: 180px;">
                            <select-tree v-model="channelAutoIDs" placeholder="请选择" :data="treeOrderChannel" title="销售渠道选择">
                            </select-tree>
                        </div>
                    </div>
                </div>
                <div class="inline-block" >
                    <div class="flex-box">
                        <el-checkbox v-model="isSubtotal" label="是否小计" ></el-checkbox>
                    </div>
                </div>
                <div class="inline-block" >
                    <div class="flex-box">
                        <div class="lable-txt">桌号:</div>
                        <div class="from-input search-input"><input type="text" v-model="selectContent"  placeholder="桌号检索" /></div>
                        <div class="search-flex">
                            <div class="bnt-search" @click="loadData()" >查询</div>
                        </div>
                    </div>
                </div>
                <div class="inline-block">
                    <div class="flex-box">
                        <div class="lable-txt">折前金额:</div>
                        <div class="from-input">
                            <el-select v-model="moneyType" placeholder="请选择" @change="loadData()">
                                <el-option label="按套餐内菜品原价统计" :value="1"></el-option>
                                <el-option label="按套餐售价统计" :value="2"></el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
                <div class="inline-block" >
                    <div class="flex-box">
                        <el-checkbox v-model="isNoTax" label="所有毛利率%都按去税金额计算" ></el-checkbox>
                    </div>
                </div>
            </div>
            <div class="right-bnts">
                <div class="bnt" @click="exportExcel()"><i class="iconfont icon-daochu"></i>导出报表</div>
            </div>
            
        </div>
        <div class="content-box clearfix">
            <div class="table-box grey-table headerCol2 isFooter" ref="tableBox"   >
                <table-vir ref="elTable" :data="tableListPage" 
                    border 
                    show-summary
                    @sort-change="sortChange" 
                    :summary-method="getSummaries"
                    :cell-class-name="setClassName"
                    @cell-dblclick="orderDetail"
                    :span-method="spanMethod"
                    highlight-current-row
                    v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}" :default-sort = "{prop: 'date', order: 'descending'}">
                    <table-column-vir fixed="left"  prop="Rpt_Date" label="营业日期" min-width="90" :formatter="shortDateFormat"></table-column-vir>
                    <table-column-vir fixed="left" prop="Eat_RoomName"  label="餐厅" min-width="110">
                        <template #default="scope">
                            <span class="lefts" v-if="scope.row.isTotal">小计：</span>
                            <span class="lefts" v-else>{{scope.row.Eat_RoomName}}</span>
                        </template>
                    </table-column-vir>
                    <table-column-vir fixed="left" prop="Eat_PhaseName" label="时段" min-width="50"></table-column-vir>
                    <table-column-vir sortable="custom" fixed="left" class-name="deskName" prop="Eat_DeskName" align="left" label="桌号" min-width="90">
                        <template #default="scope">
                            <template v-if="scope.row.isTotal">
                                {{scope.row.Eat_DeskNum}}桌
                            </template>
                            <template v-else>
                                <el-tooltip v-if="scope.row.Eat_State==1" effect="dark" content="未结账  双击查看账单明细！" placement="top">
                                    <div>{{scope.row.Eat_DeskName}}</div>
                                </el-tooltip>
                                <div v-else>{{scope.row.Eat_DeskName}}</div>
                            </template>
                        </template>
                    </table-column-vir>
                    <table-column-vir sortable="custom" prop="Eat_SaleMoney" label="折前金额" align="right" min-width="80" :formatter="priceFormat" format="number">
                        <template #header>{{moneyType==1?"原价":"折前"}}金额</template>
                    </table-column-vir>
                    <table-column-vir sortable="custom" prop="Eat_DiscountMoney" label="优惠金额" align="right" min-width="80" :formatter="priceFormat" format="number"></table-column-vir>
                    <table-column-vir sortable="custom" prop="Eat_CheckMoney" label="折后金额" align="right" min-width="80" :formatter="priceFormat" format="number"></table-column-vir>
                    <table-column-vir sortable="custom" prop="Eat_XMoney" label="虚收金额" align="right" min-width="80" :formatter="priceFormat" format="number"></table-column-vir>
                    <table-column-vir sortable="custom" prop="Eat_FactMoney" label="实收金额" align="right" min-width="80" :formatter="priceFormat" format="number"></table-column-vir>
                    <table-column-vir sortable="custom" prop="Eat_TaxMoney" label="税额" align="right" min-width="80" :formatter="priceFormat" format="number"></table-column-vir>
                    <table-column-vir sortable="custom" prop="NoTaxFactMoney" label="去税实收额" align="right" min-width="80" :formatter="priceFormat" format="number"></table-column-vir>
                    <table-column-vir sortable="custom" prop="Eat_CostMoney" label="成本金额" align="right" min-width="80" :formatter="priceFormat" format="number"></table-column-vir>
                    <table-column-vir label="毛利率%" min-width="240">
                        <table-column-vir sortable="custom" :prop="isNoTax?'Rate_NoTaxSaleMoney':'Rate_SaleMoney'" label="折前%" align="right" min-width="70" :formatter="priceFormat" format="number">
                            <template #header>{{moneyType==1?"原价":"折前"}}%</template>
                        </table-column-vir>
                        <table-column-vir sortable="custom" :prop="isNoTax?'Rate_NoTaxCheckMoney':'Rate_CheckMoney'" label="折后%" align="right" min-width="70" :formatter="priceFormat" format="number"></table-column-vir>
                        <table-column-vir sortable="custom" :prop="isNoTax?'Rate_NoTaxFactMoney':'Rate_FactMoney'" label="实收%" align="right" min-width="70" :formatter="priceFormat" format="number"></table-column-vir>
                        <table-column-vir sortable="custom" prop="Rate_NoTaxFactMoney" label="去税%" align="right" min-width="70" :formatter="priceFormat" format="number"></table-column-vir>
                    </table-column-vir>
                    <table-column-vir label="毛利额">
                        <table-column-vir sortable="custom" :prop="isNoTax?'Profit_NoTaxSaleMoney':'Profit_SaleMoney'" label="折前" align="right" min-width="70" :formatter="priceFormat" format="number">
                            <template #header>{{moneyType==1?"原价":"折前"}}</template>
                        </table-column-vir>
                        <table-column-vir sortable="custom" :prop="isNoTax?'Profit_NoTaxCheckMoney':'Profit_CheckMoney'" label="折后" align="right" min-width="70" :formatter="priceFormat" format="number"></table-column-vir>
                        <table-column-vir sortable="custom" :prop="isNoTax?'Profit_NoTaxFactMoney':'Profit_FactMoney'" label="实收" align="right" min-width="70" :formatter="priceFormat" format="number"></table-column-vir>
                        <table-column-vir sortable="custom" prop="Profit_NoTaxFactMoney" label="去税" align="right" min-width="70" :formatter="priceFormat" format="number"></table-column-vir>
                    </table-column-vir>
                    <table-column-vir sortable="custom" prop="Eat_ManNum" label="人数" min-width="60" format="number"></table-column-vir>
                    <table-column-vir label="人均" min-width="120">
                        <table-column-vir sortable="custom" prop="AVEMan_SaleMoney" label="折前" align="right" min-width="70" :formatter="priceFormat" format="number">
                            <template #header>{{moneyType==1?"原价":"折前"}}</template>
                        </table-column-vir>
                        <table-column-vir sortable="custom" prop="AVEMan_CheckMoney" label="实收" align="right" min-width="70" :formatter="priceFormat" format="number"></table-column-vir>
                    </table-column-vir>
                    <table-column-vir sortable="custom" prop="Eat_DeskNum" label="桌数" align="right" min-width="70" format="number"></table-column-vir>
                    <table-column-vir sortable="custom" prop="Eat_CheckIndex" label="结账单号" min-width="160"></table-column-vir>
                    <table-column-vir sortable="custom" prop="Channel_Name" label="销售渠道" min-width="100"></table-column-vir>
                    <table-column-vir sortable="custom" prop="XFType_Name" label="消费类型" min-width="100"></table-column-vir>
                    <table-column-vir sortable="custom" prop="Eat_Czy" label="开台人" min-width="100"></table-column-vir>
                    <table-column-vir sortable="custom" prop="Eat_BTime" label="开台时间" min-width="130" :formatter="dateFormat"></table-column-vir>
                    <table-column-vir sortable="custom" prop="Eat_ETime" label="结账时间" min-width="130" :formatter="dateFormat"></table-column-vir>
                </table-vir>
            </div>
        </div>
     </div>
</template>
<script>

/**每日账单毛利率表 */
export default {
    name:'billSaleReport',
    data(){
        return {
            currentPage:1,
            pagesize:20,//每页的数据条数
            userInfo:'',
            foodtableList:[],
            //排序
            sortable:null,
            /**默认日期范围 */
            defaultTime:[new Date(),new Date()],
            /**选中日期范围 */
            businessHours:[new Date(),new Date()],
            /**true: 日期范围    false：当前营业数据*/
            queryType:false,
            //台号检索
            selectContent:"",
            //餐厅id
            roomids:[],
            //时段数据
            eatPhases:null,
            //时段
            salesValue:[],
            //消费类型数据
            consumeTypes:null,
            //消费类型id
            typeAutoIDs:[],
            //渠道数据
            orderChannel:null,
            //渠道id
            channelAutoIDs:[],
            //桌台数据
            PosRooms:[],
            //是否小计
            isSubtotal:true,
            /**折前金额 计算方式 1 按套餐内菜品原价统计 2、按套餐售价统计*/
            moneyType:1,
            //所有毛利率%都按去税金额计算
            isNoTax:true
        }
    },
    computed:{
        //限制门店用户查看几天以内的报表, -1:不能查看历史数据，0:不限制，大于0：限制查看几天内的报表
        ReportDataFromDays(){
            return this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserViewReportDataFromDays||0
        },
        //大类数据
        foodMainCategory(){
            let foodMainCategory=[];
            this.posFoodData?.data?.map(item=>{
                let depart={
                    id:item.MainCategory_ID,
                    Name:item.MainCategory_Name,
                    children:[],
                    Hierarchy:1
                }
                if(item.FoodSubCategorys){
                    item.FoodSubCategorys.map(eat=>{
                        let chieat={
                            id:eat.SubCategory_ID,
                            Name:eat.SubCategory_Name,
                            Hierarchy:2
                        }
                        depart.children.push(chieat)
                    })
                }
                foodMainCategory.push(depart)
            })
            return foodMainCategory;
        },
        //消费类型数据
        treeConsumeTypes(){
            let treeData=[];
            this.consumeTypes?.map((group)=>{
                let json={
                    id:group.ConsumeTypeKind_AutoID,
                    name:group.ConsumeTypeKind_Code+group.ConsumeTypeKind_Name,
                    children:[],
                    Hierarchy:1
                }
                if(group.ConsumeTypeList){
                    group.ConsumeTypeList.map(item=>{
                        let children= Object.assign({
                            id:item.ConsumeType_AutoID,
                            name:item.ConsumeType_Code+'-'+item.ConsumeType_Name,
                            Hierarchy:2
                        },item)
                        json.children.push(children)
                    })
                }
                treeData.push(json)
            })
            return treeData;
        },
        //渠道数据
        treeOrderChannel(){
            let treeData=[];
            this.orderChannel?.map((group)=>{
                let json={
                    id:group.OrderChannelKind_AutoID,
                    name:group.OrderChannelKind_Code+group.OrderChannelKind_Name,
                    children:[],
                    Hierarchy:1
                }
                if(group.OrderChannelList){
                    group.OrderChannelList.map(item=>{
                        let children= Object.assign({
                            id:item.OrderChannel_AutoID,
                            name:item.OrderChannel_Code+'-'+item.OrderChannel_Name,
                            Hierarchy:2
                        },item)
                        json.children.push(children)
                    })
                }
                treeData.push(json)
            })
            return treeData;
        },
        /**筛选 或 排序 */
        tableFilter(){
            let list=Object.assign([],this.foodtableList);
            if(this.sortable && this.sortable.order){

                if(this.isSubtotal){
                    let groupList={}
                    list?.forEach(item=>{
                        let key=item.Rpt_Date+item.Eat_RoomCode;
                        if(!groupList[key]){
                            groupList[key]=[];
                        }
                        groupList[key].push(item);
                    })
                    list=[];
                    let sorttype=this.sortable.order=='ascending'?'asc':'desc';
                    Object.keys(groupList).forEach(key=>{
                        groupList[key].order(this.sortable.prop,sorttype);
                        groupList[key].forEach(item=>{
                            list.push(item)
                        })
                    })
                }else{
                    let sorttype=this.sortable.order=='ascending'?'asc':'desc';

                    list.order(this.sortable.prop,sorttype);
                }
            } 
            
            return list;
        },
        /**合计数据 */
        summaryTableList(){
            let group={};//小计
            let total={
                Eat_SaleMoney:0,
                NoTaxSaleMoney:0,//去税折前金额
                Eat_DiscountMoney:0,
                Eat_CheckMoney:0,
                NoTaxCheckMoney:0,//去税折前金额
                Eat_XMoney:0,
                Eat_FactMoney:0,
                Eat_TaxMoney:0,
                NoTaxFactMoney:0,
                Eat_CostMoney:0,
                Rate_SaleMoney:0,//折前%
                Rate_NoTaxSaleMoney:0,//去税 折前%
                Rate_CheckMoney:0,//折后%
                Rate_NoTaxCheckMoney:0,//去税 折后%
                Rate_FactMoney:0,
                Rate_NoTaxFactMoney:0,
                Profit_SaleMoney:0,
                Profit_NoTaxSaleMoney:0,//去税折前毛利额
                Profit_CheckMoney:0,
                Profit_NoTaxCheckMoney:0,//去税实收毛利额
                Profit_FactMoney:0,
                Profit_NoTaxFactMoney:0,
                Eat_ManNum:0,
                AVEMan_SaleMoney:0,
                AVEMan_CheckMoney:0,
                Eat_DeskNum:0,
                Eat_DeskName:""
            };//合计
            this.tableFilter?.forEach((it)=>{
                let key=it.Rpt_Date+it.Eat_RoomCode;
                if(!group[key]){
                    group[key]={
                        isTotal:true,
                        Eat_SaleMoney:0,
                        NoTaxSaleMoney:0,
                        Eat_DiscountMoney:0,
                        Eat_CheckMoney:0,
                        NoTaxCheckMoney:0,
                        Eat_XMoney:0,
                        Eat_FactMoney:0,
                        Eat_TaxMoney:0,
                        NoTaxFactMoney:0,
                        Eat_CostMoney:0,
                        Rate_SaleMoney:0,
                        Rate_NoTaxSaleMoney:0,
                        Rate_CheckMoney:0,
                        Rate_NoTaxCheckMoney:0,
                        Rate_FactMoney:0,
                        Rate_NoTaxFactMoney:0,
                        Profit_SaleMoney:0,
                        Profit_NoTaxSaleMoney:0,
                        Profit_CheckMoney:0,
                        Profit_NoTaxCheckMoney:0,
                        Profit_FactMoney:0,
                        Profit_NoTaxFactMoney:0,
                        Eat_ManNum:0,
                        AVEMan_SaleMoney:0,
                        AVEMan_CheckMoney:0,
                        Eat_DeskNum:0
                    }
                }
                //小计
                group[key].Eat_SaleMoney+=it.Eat_SaleMoney;
                group[key].NoTaxSaleMoney+=it.NoTaxSaleMoney;
                group[key].Eat_DiscountMoney+=it.Eat_DiscountMoney;
                group[key].Eat_CheckMoney+=it.Eat_CheckMoney;
                group[key].NoTaxCheckMoney+=it.NoTaxCheckMoney;
                group[key].Eat_XMoney+=it.Eat_XMoney;
                group[key].Eat_FactMoney+=it.Eat_FactMoney;
                group[key].Eat_TaxMoney+=it.Eat_TaxMoney;
                group[key].NoTaxFactMoney+=it.NoTaxFactMoney;
                group[key].Eat_CostMoney+=it.Eat_CostMoney;
                group[key].Profit_SaleMoney+=it.Profit_SaleMoney;
                group[key].Profit_NoTaxSaleMoney+=it.Profit_NoTaxSaleMoney;
                group[key].Profit_CheckMoney+=it.Profit_CheckMoney;
                group[key].Profit_NoTaxCheckMoney+=it.Profit_NoTaxCheckMoney;
                group[key].Profit_FactMoney+=it.Profit_FactMoney;
                group[key].Profit_NoTaxFactMoney+=it.Profit_NoTaxFactMoney;
                group[key].Eat_ManNum+=it.Eat_ManNum;
                group[key].Eat_DeskNum+=it.Eat_DeskNum;
                //合计
                total.Eat_SaleMoney+=it.Eat_SaleMoney||0;
                total.NoTaxSaleMoney+=it.NoTaxSaleMoney||0;
                total.Eat_DiscountMoney+=it.Eat_DiscountMoney||0;
                total.Eat_CheckMoney+=it.Eat_CheckMoney||0;
                total.NoTaxCheckMoney+=it.NoTaxCheckMoney||0;
                total.Eat_XMoney+=it.Eat_XMoney||0;
                total.Eat_FactMoney+=it.Eat_FactMoney||0;
                total.Eat_TaxMoney+=it.Eat_TaxMoney||0;
                total.NoTaxFactMoney+=it.NoTaxFactMoney||0;
                total.Eat_CostMoney+=it.Eat_CostMoney||0;
                total.Profit_SaleMoney+=it.Profit_SaleMoney||0;
                total.Profit_NoTaxSaleMoney+=it.Profit_NoTaxSaleMoney||0;
                total.Profit_CheckMoney+=it.Profit_CheckMoney||0;
                total.Profit_NoTaxCheckMoney+=it.Profit_NoTaxCheckMoney||0;
                total.Profit_FactMoney+=it.Profit_FactMoney||0;
                total.Profit_NoTaxFactMoney+=it.Profit_NoTaxFactMoney||0;
                total.Eat_ManNum+=it.Eat_ManNum||0;
                total.Eat_DeskNum+=it.Eat_DeskNum||0;
            });
            
            total.Eat_DeskName=total.Eat_DeskNum+"桌";
            group["total"]=total;
            console.log(total)
            for(let key in group){
                group[key].Rate_SaleMoney=group[key].Eat_SaleMoney==0?0: parseFloat((group[key].Profit_SaleMoney/group[key].Eat_SaleMoney*100).toFixed(2));
                group[key].Rate_NoTaxSaleMoney=group[key].NoTaxSaleMoney==0?0: parseFloat((group[key].Profit_NoTaxSaleMoney/group[key].NoTaxSaleMoney*100).toFixed(2));
                group[key].Rate_CheckMoney=group[key].Eat_CheckMoney==0?0: parseFloat((group[key].Profit_CheckMoney/group[key].Eat_CheckMoney*100).toFixed(2));
                group[key].Rate_NoTaxCheckMoney=group[key].NoTaxCheckMoney==0?0: parseFloat((group[key].Profit_NoTaxCheckMoney/group[key].NoTaxCheckMoney*100).toFixed(2));
                group[key].Rate_FactMoney=group[key].Eat_FactMoney==0?0: parseFloat((group[key].Profit_FactMoney/group[key].Eat_FactMoney*100).toFixed(2));
                group[key].Rate_NoTaxFactMoney=group[key].NoTaxFactMoney==0?0: parseFloat((group[key].Profit_NoTaxFactMoney/group[key].NoTaxFactMoney*100).toFixed(2));

                group[key].AVEMan_SaleMoney=group[key].Eat_ManNum==0?0:parseFloat((group[key].Eat_SaleMoney/group[key].Eat_ManNum).toFixed(2));
                group[key].AVEMan_CheckMoney=group[key].Eat_ManNum==0?0:parseFloat((group[key].Eat_FactMoney/group[key].Eat_ManNum).toFixed(2));
            }
            return group;
        },
        tableListPage(){
            let pageList=[];
            let list=this.tableFilter;
            if(list?.length>0){
                let key=list[0].Rpt_Date+list[0].Eat_RoomCode;
               
                pageList.push(list[0])
                for(let i=1;i<list.length;i++){
                    if(key!=list[i].Rpt_Date+list[i].Eat_RoomCode){
                        if(this.isSubtotal){
                            pageList.push(this.summaryTableList[key]);
                        }
                        key=list[i].Rpt_Date+list[i].Eat_RoomCode;
                    }
                    pageList.push(list[i])
                }
                if(this.isSubtotal){
                    pageList.push(this.summaryTableList[key]);
                }
            }
            return pageList;
        }
    },
    mounted(){
        this.userInfo = this.$auth.getUserInfo();
        this.nowadays();
        

        //时段数据
        this.$cacheData.EatPhase().then((d)=>{
            if(d){
                this.eatPhases=d;
            }
        }).catch((e)=>{
            this.$message.error('加载时段数据失败：'+e.message);
            console.log('加载时段数据失败：'+e);
        })
        console.log(this.pagesize)
        //渠道数据
        this.$cacheData.OrderChannel().then((d)=>{
            if(d){
                this.orderChannel=d;
            }
        }).catch((e)=>{
            this.$message.error('加载渠道数据失败：'+e.message);
            console.log('加载渠道数据失败：'+e);
        })

        //消费类型
        this.$cacheData.ConsumeTypes().then((d)=>{
            if(d){
                this.consumeTypes=d;
            }
        }).catch((e)=>{
            this.$message.error('加载消费类型数据失败：'+e.message);
            console.log('加载消费类型数据失败：'+e);
        })

        this.$cacheData.EatRooms().then((d)=>{
            this.PosRooms=d;
        }).catch((e)=>{
            this.$alert("桌台数据加载失败"+e.message, "提示", {confirmButtonText: "确定"});
        });
    },
    methods:{
        disabledDate(d){
            if(this.ReportDataFromDays>0 && this.userInfo.Rpt_Date){
                const oneDay =  24 * 3600 * 1000;
                const day = this.ReportDataFromDays * oneDay;
                let maxDate=new Date(this.userInfo.Rpt_Date).getTime()-oneDay;
                let minDate=maxDate-day;
                if(minDate>=d.getTime() || maxDate<d.getTime()){
                    return true;
                }
            }
            return false;
        },
        //设置列类名
        setClassName({ row, rowIndex }){
            if(row.isTotal){
                return "total";
            }else if(row.Eat_State==1){//1未结账  2已结账
                return "unclosed";
            }
            return '';
        },
        /**Excel导出 */
        exportExcel(){
            if(!this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserPrintReports){
                this.$message.warning('您没有导出报表权限，请先分配权限！');
                return;
            }
            if(this.$refs.elTable){

                this.$refs.elTable.export("每日账单毛利率表")

                // let list=this.getExportList(this.foodtableList);
                // this.$excelCommon.elTableToExcel({
                //     elTable:this.$refs.elTable,
                //     titleName:"每日账单毛利率表",
                //     list:list
                // })
            }
        },
        /**今日 */
        nowadays(){
            this.queryType=false
            let Rpt_Date=new Date(this.userInfo.Rpt_Date);
            Rpt_Date.setDate(Rpt_Date.getDate()-1);
            this.businessHours=[Rpt_Date.Format("yyyy-MM-dd"),Rpt_Date.Format("yyyy-MM-dd")];
        },
        change(){
            if(this.queryType==false){
                this.queryType=true
            }
        },
         /*单价格式 截取两位小数 */
        priceFormat(row, column, cellValue){
            if(isNaN(cellValue)){
                return ""
            }else{
                return parseFloat(parseFloat(cellValue).toFixed(2));
            }
        },
        /*时间格式 */
        dateFormat(row, column, cellValue) {
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            return (new Date(cellValue)).Format('yyyy-MM-dd hh:mm:ss');
        },
        /*时间格式 */
        shortDateFormat(row, column, cellValue){
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            return (new Date(cellValue)).Format('yyyy-MM-dd');
        },
        /**合并行列 */
        spanMethod({ row, column, rowIndex, columnIndex }){
            if(row.isTotal){
                if(columnIndex==1){
                    return [1,2];
                }else if(columnIndex==2){
                    return [0,0];
                }
            }
        },
        //合计 
        getSummaries({ columns, data }){
            const sums= [];
            let total=this.summaryTableList["total"];
            columns.forEach((column, index) => {
                if(total[column.property]!=undefined){
                    if(column.property=="Eat_DeskName"){
                        sums[index]=total[column.property];
                    }else{
                        sums[index]=parseFloat(total[column.property].toFixed(2));
                    }
                    
                }else{
                    sums[index]="";
                }
            })
            sums[0]="合计";
            return sums;
        },
        /**账单数据排序 */
        sortChange(column){
            this.sortable=column;
        },
        //要导出的数据
        getExportList(list){
            let pageList=[];
            if(list?.length>0){
                let key=list[0].Rpt_Date+list[0].Eat_RoomCode;
               
                pageList.push(list[0])
                for(let i=1;i<list.length;i++){
                    if(key!=list[i].Rpt_Date+list[i].Eat_RoomCode){
                        pageList.push(this.summaryTableList[key]);
                        key=list[i].Rpt_Date+list[i].Eat_RoomCode;
                    }
                    pageList.push(list[i])
                }
                pageList.push(this.summaryTableList[key]);
            }
            return pageList;
        },
        /**查询 */
        loadData(){
            let time = JSON.parse(JSON.stringify(this.businessHours));
            let param={
                User_ID:this.userInfo?.User_ID,
                Operator_Name:this.userInfo?.Login_Name,
                DateRange_YN:this.queryType,//是否日期范围查询  true: 日期范围    false：当前营业数据
                Begin_Date:new Date(time[0]).Format("yyyy-MM-dd"),//开始日期
                End_Date:new Date(time[1]).Format("yyyy-MM-dd"), //结束日期
                Eat_PhaseIDs:this.salesValue,
                Eat_RoomIDs:this.roomids,
                ChannelIDs:this.channelAutoIDs,
                XFTypeIDs:this.typeAutoIDs,
                Eat_DeskNo:this.selectContent,
                SaleMoneyType:this.moneyType,//折前金额类型  1.按套餐内菜品原价统计  2、按套餐售价统计
                IsHideMarkedBills:this.$global.isLableData //是否隐藏带标签的订单
            }
            const loading = this.$loading({
                text: "查询中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });

            this.$httpAES.requestConfig({timeout:60*1000}).post("Bestech.CloudPos.BillCostReport",param).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.foodtableList=d.ResponseBody;
                }else{
                    this.foodtableList=[];
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error(e.message);
                console.log(e);
            })
        },
        //穿透到 明细报表
        orderDetail(row,column){
            if(column.property=="Eat_DeskName" && !row.isTotal){
                this.$emit("addFrame",{
                    key:row.Eat_Autoid,
                    data:{Eat_AutoID:row.Eat_Autoid,Rpt_Date:row.Rpt_Date,SaleMoneyType:this.moneyType},
                    title:'菜品毛利详情表',
                    url:'reportBillCostDetail'
                });
            }
        },
    }
}
</script>

<style lang="scss" >
@import './reportBillCost.scss'
</style>